import React from "react";
import sliderImage from "../../assests/image/one.jpg";
import fourImg from "../../assests/image/four.jpg";
import geige from "../../assests/image/we.jpg";
import sliderImageone from "../../assests/image/sliderone.jpg";
import imageOne from "../../assests/image/two.jpg";
import imageTwo from "../../assests/image/a.jpg";
import imageThree from "../../assests/image/bubble-crepe.jpg";
import imageFour from "../../assests/image/chiffon.jpg";
import "../Mission/Mission.scss";
import strength1 from "../../assests/image/strength1.jpg";
import quality from "../../assests/image/quality.jpg";
import innovate from "../../assests/image/innovate.jpg";
import tech from "../../assests/image/technology.jpg";
import support from "../../assests/image/support.jpg";

function Mission(props) {
  return (
    <div>
      <div className="gallery_header">
        <p className="gallery_header_text">Strength</p>
      </div>
      <div className="mission_wrapper">
        <div className="container">
          <div
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease"
            data-aos-mirror="false"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
            className="row home_topmargin"
          >
            <div className="col-lg-5">
              <div className="home_image">
                <img src={quality} alt="Slider" />
              </div>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-6">
              <div className="home_right">
                <h5>QUALITY</h5>
                <p>
                  At JK Weavelab, product quality is of the utmost significance.
                  We use comprehensive testing techniques to provide superior
                  quality control throughout our entire manufacturing process in
                  order to meet all quality requirements.
                </p>
              </div>
              {/* <button className="btn custom_project_btn">Read More</button> */}
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease"
            data-aos-mirror="false"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
            className="row home_topmargin"
          >
            <div className="col-lg-6">
              <div className="home_right">
                <h5>TECHNOLOGY & MANUFACTURING</h5>
                <p>
                  In order to adopt the manufacturing technology that is widely
                  accepted around the world and to deliver speed, versatility,
                  and most importantly, quality, JK WeaveLab continuously
                  invests in cutting-edge technology and equipment across all of
                  our areas of operations.
                </p>
              </div>
              {/* <button className="btn custom_project_btn">Read More</button> */}
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-5">
              <div className="home_image">
                <img src={tech} />
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease"
            data-aos-mirror="false"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
            className="row home_topmargin"
          >
            <div className="col-lg-5">
              <div className="home_image">
                <img src={innovate} />
              </div>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-6">
              <div className="home_right">
                <h5>INNOVATION & DESIGN</h5>
                <p>
                  Since we've been in operation, we've always been on par with
                  adopting technological and innovative advances. We are able to
                  break new ground in the market thanks to our constant product
                  development process, as well as the expertise and knowledge of
                  our R&D staff.
                </p>
              </div>
              {/* <button className="btn custom_project_btn">Read More</button> */}
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease"
            data-aos-mirror="false"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
            className="row home_topmargin"
          >
            <div className="col-lg-6">
              <div className="home_right">
                <h5>SOURCING & INVENTORY</h5>
                <p>
                  All of our customers are invited to a completely new level of
                  yarn and fabric procurement at JK WeaveLab. We provide a
                  one-stop shop for all your yarn and fabric needs with an
                  inventory system that includes a large selection of yarns and
                  fabrics to choose from.
                </p>
              </div>
              {/* <button className="btn custom_project_btn">Read More</button> */}
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-5">
              <div className="home_image">
                <img src={strength1} />
              </div>
            </div>
          </div>
          <div
            data-aos="fade-up"
            data-aos-offset="0"
            data-aos-delay="0"
            data-aos-duration="600"
            data-aos-easing="ease"
            data-aos-mirror="false"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
            className="row home_topmargin"
          >
            <div className="col-lg-5">
              <div className="home_image">
                <img src={support} />
              </div>
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-6">
              <div className="home_right">
                <h5>SUPPORT</h5>
                <p>
                  In all facets of our business, including customer service, we
                  put quality first. The highly responsive and committed staff
                  at JK WeaveLab makes sure we consistently surpass client
                  expectations by comprehending the fundamental needs of our
                  customers and providing pre-sales and post-sales care.
                </p>
              </div>
              {/* <button className="btn custom_project_btn">Read More</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
