import React from "react";
import SwiperSlider from "../SwiperSlider";
import "../HomeProduct/HomeProduct.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../SwiperSlider/SwiperSlider.scss";
import { Navigation, Autoplay } from "swiper";
import sliderImage from "../../assests/image/one.jpg";
import fourImg from "../../assests/image/four.jpg";
import geige from "../../assests/image/we.jpg";
import sliderImageone from "../../assests/image/sliderone.jpg";
import imageOne from "../../assests/image/two.jpg";
import imageTwo from "../../assests/image/a.jpg";
import imageThree from "../../assests/image/bubble-crepe.jpg";
import imageFour from "../../assests/image/chiffon.jpg";
import sustain from "../../assests/image/sustain.jpg";
import woven from "../../assests/image/non-woven.jpg";
import Seo from "../Seo";

function HomeProduct(props) {
  return (
    <div className="home_product_wrapper">
      <Seo
        title="JK Weavelab"
        description="JK Weavelab is a firm which weaves cloth with total ambition."
        name="JK Weavelab."
        type="website"
        url="https://jkweavelab.com"
      />
      <div className="">
        <SwiperSlider />
        <div className="home_content_wrapper">
          <div className="container">
            <div className="home_content">
              <div
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-duration="600"
                data-aos-easing="ease"
                data-aos-mirror="false"
                data-aos-once="false"
                data-aos-anchor-placement="top-center"
                className="row home_topmargin"
              >
                <div className="col-lg-5">
                  <div className="home_image">
                    <img src={sliderImage} alt="Slider" />
                  </div>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-6">
                  <div className="home_right">
                    <h5 className="">SOLID-DYED FABRICS</h5>
                    <p>
                      In order to ensure that we satisfy the highest quality
                      standards, we have worked with leading fabric processing
                      companies.
                    </p>
                  </div>
                  {/* <button className="btn custom_project_btn">Read More</button> */}
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-duration="600"
                data-aos-easing="ease"
                data-aos-mirror="false"
                data-aos-once="false"
                data-aos-anchor-placement="top-center"
                className="row home_topmargin"
              >
                <div className="col-lg-6 order-3 order-lg-1">
                  <div className="home_right">
                    <h5>PRINTED FABRICS</h5>
                    <p>
                      We also provide a variety of printed fabrics made using
                      rotary, flatbed, and digital printing methods thanks to
                      our collaboration with leading fabric processing
                      companies.
                    </p>
                  </div>
                  {/* <button className="btn custom_project_btn">Read More</button> */}
                </div>
                <div className="col-lg-1 order-2 order-lg-2" />
                <div className="col-lg-5 order-1 order-lg-3">
                  <div className="home_image">
                    <img src={fourImg} />
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-duration="600"
                data-aos-easing="ease"
                data-aos-mirror="false"
                data-aos-once="false"
                data-aos-anchor-placement="top-center"
                className="row home_topmargin"
              >
                <div className="col-lg-5">
                  <div className="home_image">
                    <img src={geige} />
                  </div>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-6">
                  <div className="home_right">
                    <h5>GREIGE FABRICS</h5>
                    <p>
                      With a capacity of weaving 3.0million meters per month, we
                      are one of the largest and most renowned greige fabric
                      manufacturers in India.
                    </p>
                  </div>
                  {/* <button className="btn custom_project_btn">Read More</button> */}
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-duration="600"
                data-aos-easing="ease"
                data-aos-mirror="false"
                data-aos-once="false"
                data-aos-anchor-placement="top-center"
                className="row home_topmargin"
              >
                <div className="col-lg-6 order-3 order-lg-1">
                  <div className="home_right">
                    <h5>SUSTAINABLE FABRICS</h5>
                    <p>
                      Because we care deeply about how we affect the
                      environment, we produce sustainable fabrics from recycled
                      yarns in addition to having environmentally friendly
                      infrastructure.
                    </p>
                  </div>
                  {/* <button className="btn custom_project_btn">Read More</button> */}
                </div>
                <div className="col-lg-1 order-2 order-lg-2" />
                <div className="col-lg-5 order-1 order-lg-3">
                  <div className="home_image">
                    <img src={sustain} />
                  </div>
                </div>
              </div>
              <div
                data-aos="fade-up"
                data-aos-offset="0"
                data-aos-delay="0"
                data-aos-duration="600"
                data-aos-easing="ease"
                data-aos-mirror="false"
                data-aos-once="false"
                data-aos-anchor-placement="top-center"
                className="row home_topmargin"
              >
                <div className="col-lg-5">
                  <div className="home_image">
                    <img src={woven} />
                  </div>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-6">
                  <div className="home_right">
                    <h5>NON WOVEN FABRICS</h5>
                    <p>
                      Our nonwoven company is the newest producer of Spunbond
                      fabrics made from PP (Polypropylene). In order to produce
                      high-quality non-woven fabrics for the home furnishings,
                      healthcare, and hygiene industries, we uphold the
                      strictest standards of cleanliness at our manufacturing
                      plant. We can provide fabrics with weights ranging from 15
                      to 140 GSM in a range of colours and widths.
                    </p>
                  </div>
                  {/* <button className="btn custom_project_btn">Read More</button> */}
                </div>
              </div>
            </div>
            <h3 className="heading_text">Our Work's</h3>
            <div
              // data-aos="fade"
              // data-aos-offset="0"
              // data-aos-delay="0"
              // data-aos-duration="600"
              // data-aos-easing="ease"
              // data-aos-mirror="false"
              // data-aos-once="false"
              // data-aos-anchor-placement="top-center"
              className="product-slider"
            >
              <Swiper
                navigation={true}
                modules={[Navigation, Autoplay]}
                spaceBetween={0}
                slidesPerView={4}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="slider_wrapper">
                    <div className="slider_image_wrapper">
                      <img src={imageOne} alt="imageOne" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="slider_image_wrapper">
                      <img src={imageTwo} alt="imageTwo" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slider_wrapper">
                    <div className="slider_image_wrapper">
                      <img src={imageThree} alt="imageThree" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="slider_image_wrapper">
                      <img src={imageFour} alt="imageFour" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div>
                    <div className="slider_image_wrapper">
                      <img src={sliderImageone} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="slider_wrapper">
                    <div className="slider_image_wrapper">
                      <img src={sliderImage} />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeProduct;
