import React from "react";
import "../Gallery/Gallery.scss";
import one from "../../../src/assests/fabric/bubble-crepe.jpg";
import two from "../../../src/assests/fabric/chiffon.jpg";
import three from "../../../src/assests/fabric/ggt.jpeg";
import four from "../../../src/assests/fabric/cord.jpg";
import five from "../../../src/assests/fabric/chiffon-ggt.jpg";
import six from "../../../src/assests/fabric/organza-silk.jpg";
import seven from "../../../src/assests/fabric/moss.jpg";
import eight from "../../../src/assests/fabric/satin.jpg";
import nine from "../../../src/assests/fabric/satin-crap.jpg";
import ten from "../../../src/assests/fabric/silk-satin.jpg";
import eleven from "../../../src/assests/fabric/silk-crepe.jpg";
import twelve from "../../../src/assests/fabric/tafetta.jpg";


function Gallery(props) {
  return (
    <>
      <div className="gallery_header">
        <p className="gallery_header_text">Gallery</p>
      </div>
      <div className="container">
        <div className="galleryWrapper">
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="0"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
            <img src={one} alt="clothing"/>
            <p className="image_text">Bubble Crepe</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
            <img src={two} alt="clothing"/>
            <p className="image_text">Chiffon</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
                        <img src={three} alt="clothing-3"/>
                        <p className="image_text">GGT</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
                        <img src={four} alt="clothing-4"/>
                        <p className="image_text">Cord</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
            <img src={five} alt="clothing-5"/>
            <p className="image_text">Chiffon-GGT</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
                        <img src={six} alt="clothing-6"/>
                        <p className="image_text">Organza Silk</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
             <img src={twelve} alt="clothing-6"/>
             <p className="image_text">Tafetta</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="700"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
              <img src={seven} alt="clothing-6"/>
              <p className="image_text">Moss</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
              <img src={eight} alt="clothing-6"/>
              <p className="image_text">Satin</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="400"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
              <img src={nine} alt="clothing-6"/>
              <p className="image_text">Satin-Crap</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="500"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
              <img src={ten} alt="clothing-6"/>
              <p className="image_text">Silk Satin</p>
          </div>
          <div
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-mirror="true"
            data-aos-once="false"
            className="galleryimageWrapper"
          >
             <img src={eleven} alt="clothing-6"/>
             <p className="image_text">Silk Crepe</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
