import React from "react";
import "../Header/Header.scss";
import { Link, useLocation } from "react-router-dom";
import logoImg from "../../assests/image/JK.svg";
import ham from "../../assests/image/hamburger.png";

function Header(props) {
    const router = useLocation();
    const route = router.pathname;
  return (
    <>
    <div className="header-wrapper">
      <nav className="navbar navbar-expand-lg w-100">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src={logoImg} alt="WeaveLogo"/>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <img src={ham} alt="hamburger"/>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className={route === "/home" ? "activeclass" : null}>
                <Link to="/home">Home</Link>
              </li>
              <li className={route === "/about" ? "activeclass" : null}>
                <Link to="/about">About Us</Link>
              </li>
              <li className={route === "/gallery" ? "activeclass" : null}>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li className={route === "/mission" ? "activeclass" : null}>
                <Link to="/mission">Strength</Link>
              </li>
              <li className={route === "/contact" ? "activeclass" : null}>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    </>
  );
}

export default Header;
