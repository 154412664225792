import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../SwiperSlider/SwiperSlider.scss";
import { Navigation, Autoplay } from "swiper";
import sliderImage from "../../assests/image/dance.jpg";
import sliderImageone from "../../assests/image/dancetwo.jpg";
import back from "../../assests/image/dancethree.jpg";
import back2 from "../../assests/image/dancefour.jpg";
// const sliderImage = "/assests/image/hero.jpg";
// const sliderImageone = "/assests/image/sliderone.jpg";

function SwiperSlider(props) {
  return (
    <div>
      <Swiper
        navigation={true}
        modules={[Navigation, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div className="slider_wrapper">
            <p className="slider_head"></p>
            <div className="slider_image_wrapper">
              <img src={sliderImage} alt="imageone"/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider_wrapper">
          <p className="slider_head_white"></p>
            <div className="slider_image_wrapper">
              <img src={sliderImageone} alt="Imageone"/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider_wrapper">
            <p className="slider_head"></p>
            <div className="slider_image_wrapper">
              <img src={back} alt="Imaging one"/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider_wrapper">
          <p className="slider_head_white"></p>
            <div className="slider_image_wrapper">
              <img src={back2} alt="wrapper"/>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default SwiperSlider;
