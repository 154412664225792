import React, { useState } from "react";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import Gallery from "./components/Gallery/Gallery";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import { Routes, Route } from "react-router-dom";
import Reed from "../src/components/Reed";
import HomeProduct from "./components/HomeProduct/HomeProduct";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Mission from "./components/Mission/Mission";
import Loader from "./components/Loader/Loader";
import { HelmetProvider } from "react-helmet-async";

function App(props) {
  AOS.init();
  AOS.init({
    disable: false,
    startEvent: "DOMContentLoaded",
    initClassName: "aos-init",
    animatedClassName: "aos-animate",
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,
    offset: 120,
    delay: 0,
    duration: 400,
    easing: "ease",
    once: false,
    mirror: false,
    anchorPlacement: "top-bottom",
  });
  const [loader, setLoader] = useState(true);

  setTimeout(() => {
    setLoader(false);
  }, 4000);

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <div>
        {loader && <Loader />}
        {!loader && (
          <>
            <Header />
            <Routes>
              <Route path="/" element={<HomeProduct />} />
              <Route path="/home" element={<HomeProduct />} />
              <Route path="/about" element={<About />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
            <Footer />
          </>
        )}
        {/* <Reed /> */}
      </div>
    </HelmetProvider>
  );
}

export default App;
