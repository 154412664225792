import React from 'react';
import "../Loader/Loader.scss";
import Logo from "../../assests/image/36x36.svg";

function Loader(props) {
    return (
        <div className='loader_wrapper'>
            <div className='image_wrapper'>
                <img src={Logo} alt='loaderIMG'/>
            </div>
            <div className='text_wrapper'>
                <p>JK WEAVELAB</p>
            </div>
        </div>
    );
}

export default Loader;