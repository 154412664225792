import React from 'react';

function Footer(props) {
    return (
        <div className='footer-wrapper bg-dark text-light p-2 text-center' style={{position: "fixed", bottom: 0, width: "100%", zIndex: 9}}>
            <div>
                <small className='mb-0'>All &copy; copyrights are reserved by JK WeaveLab LLP</small>
            </div>
            {/* <div className='social_Links'>
                <div>
                    Instagram
                </div>
            </div> */}
        </div>
    );
}

export default Footer;