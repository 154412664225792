import React from 'react';
import "../Contact/Contact.scss";
import logoImg from "../../assests/image/JK.svg";

function Contact(props) {
    return (
        <div className='contact-wrapper'>
            <div className='contact_header'>
                <p className='contact_header_text'>Contact Us</p>
            </div>
            <div className='contact_content'>
                <div className='logo_image'>
                    <img src={logoImg} alt="logo"/>                    
                </div>
                <div className='mt-4 text-center'>
                    <h5 className='mb-4'>Address</h5>
                    <p>7-9, Shyamjikrupa industry, sugar factory road <br/> Sayan, Surat </p>
                </div>
                <div className='mt-2 text-center'>
                    <h5 className='mb-4'>Call Us</h5>
                    <a className='d-block mb-2' href="tel:+919979844078">+91 99798 44078</a>
                    <a className='d-block mb-2' href="tel:+919979444078">+91 99794 44078</a>
                </div>
                <div className='mt-4 text-center'>
                    <h5 className='mb-4'>Mail Us</h5>
                    <a href="mailto:jkoverseas99@gmail.com">sales@jkweavelab.com</a>
                </div>
            </div>
        </div>
    );
}

export default Contact;