import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "../About/About.scss";
import s1 from "../../assests/image/FIEO.png";
import s2 from "../../assests/image/sgcci.jpg";
import s3 from "../../assests/image/jaai.png";
import s4 from "../../assests/image/dgft.png";
import s5 from "../../assests/image/global.png";
import s6 from "../../assests/image/vastra.png";
import logo from "../../assests/image/JK.svg";

function About(props) {
  return (
    <div>
      <div className="about_header">
        <p className="about_header_text">About Us</p>
      </div>
      <div className="about_content">
        <div className="container">
          <div className="">
            <div className="about_logo_wrapper">
              <img src={logo} />
            </div>
          </div>
          <div className="about_content_wrapper">
            {/* <p>
              Morbi quis maximus orci. Etiam consectetur auctor varius. Cras vel
              ligula consequat, gravida magna et, varius leo. Integer ligula
              tellus, consectetur et tortor non, vehicula volutpat nisi.
              Phasellus pellentesque porttitor eros. Pellentesque feugiat
              ultricies metus id ultrices. Proin rhoncus quam tortor, a euismod
              ipsum iaculis vitae. Quisque in eros mollis, egestas eros nec,
            </p> */}
            <p>
              We believe quality is a way of life, and quality assurance is an
              integral and a fundamental part of the way we operate. All our
              products, as well as our manufacturing processes and equipment,
              are continuously and thoroughly examined to check if they match
              the highest quality standards. We adhere to globally recognised
              certification standards and compliances, as listed below –
            </p>
            {/* <p>
              Curabitur tincidunt cursus enim, quis viverra felis porttitor eu.
              Etiam ligula sapien, euismod in mi sed, auctor venenatis justo. Ut
              ut metus a justo luctus mollis a quis ex. Interdum et malesuada
              fames ac ante ipsum primis in faucibus. Nullam ullamcorper
              malesuada vulputate. Vivamus faucibus sem eget elementum euismod.
              Sed non condimentum nisl. Nunc eu maximus enim, eu pellentesque
              ligula. Suspendisse dapibus diam ante, sed tempor velit fermentum
              id. Vestibulum ornare convallis ipsum vel ultrices. Curabitur eget
              est congue ante imperdiet dignissim vel sed justo. Nullam
              ultricies in magna vel egestas. Proin ac tincidunt metus, et
              rhoncus lacus.
            </p>
            <p>
              Nullam dapibus lectus a nisl consectetur ornare. Donec
              condimentum, urna nec fermentum suscipit, purus risus facilisis
              libero, in fringilla neque odio vitae nisl. Suspendisse potenti.
              Etiam eget sodales erat. Aenean lorem mauris, sollicitudin mattis
              metus id, interdum vestibulum purus. In interdum mi mattis,
              sagittis leo in, fermentum massa. Nam pulvinar enim elit, eu
              fermentum diam aliquet et. Curabitur sit amet magna diam. Donec a
              finibus ex. Suspendisse sit amet varius leo. Mauris non risus eu
              neque rhoncus viverra. Cras volutpat leo nec cursus gravida. Etiam
              fringilla ornare lorem, et placerat felis elementum sit amet. Sed
              pulvinar tempus feugiat. Etiam pulvinar tincidunt vehicula. Etiam
              ac elit ut nisl ornare dignissim nec et libero. Integer sed nisi
              neque. Curabitur placerat est libero, eu consequat risus vehicula
              vitae. Pellentesque quam mi, rhoncus ut luctus ac, pulvinar eu
              orci. Phasellus eu neque id odio tempus ornare. Donec at dolor
              urna. Etiam commodo odio sem, at gravida nisi posuere sed. Nam
              sollicitudin sapien sit amet orci maximus, non volutpat sem
              pretium. Phasellus congue tincidunt turpis, a volutpat lorem
              lacinia id. Ut nec volutpat leo, ut condimentum nisi. Vestibulum
              aliquam nunc in tristique facilisis. Proin non turpis eu ipsum
              malesuada malesuada quis nec nunc. Quisque dapibus sapien
              tristique nibh ornare, at facilisis arcu facilisis. Ut dignissim
              feugiat dictum. Vivamus pellentesque justo leo, eget suscipit
              tortor vehicula sit amet. Praesent nec aliquet nisi.
            </p> */}
          </div>
          <div className="certificates_wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-3 mb-3">
                <div className="logo_wrapper">
                  <img src={s1} alt="logos" />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="logo_wrapper">
                  <img src={s2} alt="logos" />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="logo_wrapper">
                  <img src={s3} alt="logos" />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="logo_wrapper">
                  <img src={s4} alt="logos" />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="logo_wrapper">
                  <img src={s5} alt="logos" />
                </div>
              </div>
              <div className="col-lg-3 mb-3">
                <div className="logo_wrapper">
                  <img src={s6} alt="logos" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Swiper
              className="about_slider"
              navigation={true}
              modules={[Navigation, Autoplay]}
              spaceBetween={0}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
              }}
            >
              <SwiperSlide>
                <div className="slider_wrapper">
                  <div className="slider_image_wrapper">
                    <h5>
                      To make a positive difference in the business of our
                      customers throught the best possible customer service,
                      quality of products, and competitive prices.
                    </h5>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div>
                  <div className="slider_image_wrapper">
                    <h5>Good relationships are good business.</h5>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slider_wrapper">
                  <div className="slider_image_wrapper">
                    <h5>
                      Believing in Quality not Quantity. Providing Customer
                      Satifaction is our duty.
                    </h5>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
